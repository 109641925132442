import React, { useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";

import {
  changeControls,
  fetchAssets,
  setActiveGrade,
} from "../../redux/modules/action.creators/app.actions";
import {
  adjustmentSelector,
  modelSelector,
  modelsSelector,
  partnerListSelector,
} from "../../redux/modules/app.module";

import Seating from "../../types/Seating";
import Model from "../../types/Model";
import Adjustment from "../../types/Adjustment";

import { ContactArticle } from "../../components/contact.article.component";
import Footer from "../../components/footer.component";

import styles from "./styles.module.scss";
import { Overview } from "./overview.component";
import { PBannerComponent } from "./banner.component";
import { Settings } from "react-slick";
import Carousel from "../../components/carousel.component";
import Routes from "../../app.router/router.constants";
import { imageCompressConstants } from "../../constants/imageCompressConstants";
import { Grades } from "./details.viewer/grade.catalog";
import FeaturesList from "./features.list";
import Partner from "../../types/Partner";

interface IMapProps {
  seating?: Seating;
  partner?: Partner;
}

export const Product: React.FC<IMapProps> = ({ seating, partner }) => {
  //@ts-ignore
  let { title } = useParams();
  // const history = useHistory();
  const dispatch = useDispatch();
  const adjustment = useSelector(adjustmentSelector);
  const models = useSelector(modelsSelector);
  const model = useSelector(modelSelector);
  const partnersList = useSelector(partnerListSelector);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const buttonRef = React.useRef<HTMLDivElement>(null);

  const partnersChairs = useMemo<Model[]>(() => {
    const result: Model[] = [];

    if (!partnersList) return;

    partnersList.forEach((item: Partner) => {
      if (item.products) {
        result.push(...item.products);
      }
    });
    return result;
  }, [partnersList]);

  const updateModal = useCallback((model: Model): void => {
    if (model) {
      dispatch(changeControls({ name: "model", value: model }));
      dispatch(
        fetchAssets([model.entries[0]], "adjustments", { class: Adjustment })
      );
      dispatch(setActiveGrade(null));
      dispatch(changeControls({ name: "activeColor", value: null }));
    }
  }, []);

  React.useEffect(() => {
    if (seating && seating.entries)
      dispatch(fetchAssets(seating.entries, "models", { class: Model }));
  }, [seating]);

  React.useEffect(() => {
    if (models) {
      const selectModel = models.find(
        (model) =>
          model.title.split(" ").join("_").toLowerCase() === title.toLowerCase()
      );
      updateModal(selectModel);
    }
    if (partnersChairs) {
      const selectModel = partnersChairs.find(
        (model) =>
          model.title.split(" ").join("_").toLowerCase() === title.toLowerCase()
      );
      updateModal(selectModel);
    }
  }, [models, title, partnersChairs]);

  const renderSlider = (serial: string) => {
    const _models = models.filter((model) => model.serial === serial);
    const _contents = _models.reduce((acc, el) => {
      return [
        ...acc,
        {
          id: el.id,
          title: el.title,
          label: el.serial,
          category: el.category,
          thumbnail: `https:${el.angle_thumbnail}${imageCompressConstants.productCardThumb}`,
          price: el.price,
          gsa: el.gsa,
          isFabrix: el.isFabrix,
          isDiscounted: el.isDiscounted,
          discountValue: el.discountValue,
        },
      ];
    }, []);

    const settings: Settings = {
      dots: false,
      swipe: false,
      arrows: true,
      infinite: false,
      speed: 500,
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      dotsClass: "slider_dots",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            swipe: true,
          },
        },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            swipe: true,
            arrows: false,
            dots: true,
          },
        },
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            swipe: true,
            arrows: false,
            dots: true,
          },
        },
      ],
    };
    return _contents ? (
      <Carousel
        title={"You may also like"}
        contents={_contents}
        settings={settings}
      />
    ) : null;
  };

  const handleClickSeating = () => {
    dispatch(changeControls({ name: "filter", value: "All" }));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {model &&
            model.title
              .split(" ")
              .map((_el) => _el.charAt(0).toUpperCase() + _el.slice(1))
              .join(" ") + " | Eurotech"}
        </title>
        <meta
          name="description"
          content={adjustment && adjustment.description}
        />
      </Helmet>
      <Grid container className={styles.wrapper}>
        <div className={styles.container}>
          <Grid item xs={12} className={styles.sequences}>
            <div className={styles.sequence}>
              <Typography variant="caption" className={styles.label}>
                Seating
              </Typography>
              <span>/</span>
            </div>
            <Link
              to={
                partner
                  ? `${Routes.PartnerPage}/${partner.partnerName
                      .split(" ")
                      .join("_")
                      .toLowerCase()}`
                  : `/${seating.title.toLowerCase()}`
              }
              onClick={handleClickSeating}
              className={styles.sequence}
            >
              <Typography variant="caption" className={styles.label}>
                {seating && !partner
                  ? seating.title
                  : !seating && partner
                  ? partner.title
                  : null}
              </Typography>
              <span>/</span>
            </Link>
            <div className={styles.sequence}>
              <Typography variant="caption" className={styles.label}>
                {model && model?.title}
              </Typography>
            </div>
          </Grid>
        </div>
      </Grid>
      {adjustment && model && (
        <>
          <div className={styles.info}>
            <PBannerComponent
              seating={seating}
              specifications_new={adjustment.specifications_new}
              model={model}
              screenshots={adjustment.screenshots}
              specifications={adjustment.specifications}
            />
            <section
              className={`${styles.container} ${styles.grades}`}
              style={
                model && (model.buyNow || model.configurable)
                  ? { marginBottom: "100px" }
                  : { marginBottom: "50px" }
              }
            >
              <h1 className={styles.grades__title}>Grade / Pricing</h1>
              {model && model.gradeDescription ? (
                <h5 className={styles.grades__subtitle}>
                  {model.gradeDescription}
                </h5>
              ) : null}
              <Grades adjustment={adjustment} model={model} />
            </section>
          </div>
          {model.video && (
            <div className={styles.videoWrapper}>
              <div
                className={styles.playButton}
                ref={buttonRef}
                onClick={() => {
                  if (videoRef.current && buttonRef.current) {
                    const playPromise = videoRef.current.play();
                    if (playPromise !== undefined) {
                      buttonRef.current.style.display = 'none';
                      videoRef.current.controls = true;
                      playPromise.then().catch((e) => console.error(e));
                    }
                  }
                }}
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.3924 34.3749C11.9127 34.374 11.4415 34.2483 11.0252 34.01C10.0877 33.4788 9.50488 32.4475 9.50488 31.328V8.67177C9.50488 7.54911 10.0877 6.52099 11.0252 5.98974C11.4514 5.74477 11.9357 5.61879 12.4273 5.62497C12.9189 5.63116 13.3998 5.76928 13.8197 6.02489L33.183 17.6155C33.5866 17.8686 33.9192 18.2199 34.1498 18.6367C34.3804 19.0535 34.5014 19.522 34.5014 19.9983C34.5014 20.4747 34.3804 20.9432 34.1498 21.3599C33.9192 21.7767 33.5866 22.1281 33.183 22.3811L13.8166 33.9749C13.3868 34.2347 12.8946 34.373 12.3924 34.3749Z"
                    fill="#D13532"
                  />
                </svg>
              </div>
              <div className={styles.video}>
                <video ref={videoRef}>
                  <source src={model.video} />
                </video>
              </div>
            </div>
          )}

          <Overview
            description={adjustment.description}
            screenshots={adjustment.screenshots}
            product_sheet={adjustment.product_sheet}
            assembly_instruction={adjustment.assembly_instruction}
            dimension_sheet={adjustment.dimension_sheet}
            assembly_video={adjustment.assembly_video}
          />
          {adjustment.features_new ? (
            <section className={styles.features_wrapper}>
              <div className={styles.container}>
                <h1 className={styles.features_title}>Features</h1>
                <FeaturesList features_new={adjustment.features_new} />
              </div>
            </section>
          ) : (
            <>
              {adjustment.features &&
              adjustment.features.length &&
              Object.keys(adjustment.features[0]).length ? (
                <section className={styles.features_wrapper}>
                  <div className={styles.container}>
                    <h1 className={styles.features_title}>Features</h1>
                    <FeaturesList features={adjustment.features} />
                  </div>
                </section>
              ) : null}
            </>
          )}
        </>
      )}
      {models &&
        models.length &&
        renderSlider(
          models.find(
            (model: Model) =>
              model.title.split(" ").join("_").toLowerCase() ===
              title.toLowerCase()
          )?.serial
        )}
      <ContactArticle />
      <Footer />
    </React.Fragment>
  );
};
