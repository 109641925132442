import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Transition } from "react-transition-group";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { HashLink as Link } from "react-router-hash-link";
import Routes from "../../app.router/router.constants";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import InputBase from "@material-ui/core/InputBase";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Avatar, Box, Drawer } from "@material-ui/core";
import { isSafari } from "react-device-detect";
import PlayCircleFilledWhiteOutlinedIcon from "@material-ui/icons/PlayCircleFilledWhiteOutlined";

import { MenuControl } from "../buttons";

import {
  contractsSelector,
  drawerStateSelector,
  matchesSelector,
  panelsSelector,
  partnerListSelector,
  searchingSelector,
  searchSelector,
  toolsSelector,
} from "../../redux/modules/app.module";
import {
  fullscreenSelector,
  seatingsSelector,
} from "../../redux/modules/customizer.module";
import {
  changeControls,
  fetchContracts,
  fetchTools,
  searchRequest,
} from "../../redux/modules/action.creators/app.actions";

import Seating from "../../types/Seating";
import Model from "../../types/Model";

import styles from "./styles.module.scss";
import { ToolsCategories } from "../../types/ITools";
import { imageCompressConstants } from "../../constants/imageCompressConstants";
import Partner from "../../types/Partner";
import { useDebounce } from "../../hooks/useDebounced";
import Contentful from "../../utils/app.helpers/contentful";

const SearchProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 2,
      width: "100%",
      borderRadius: 1,
      position: "absolute",
      left: 0,
      bottom: "-1px",
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[200],
    },
    bar: {
      borderRadius: 1,
      backgroundColor: "#e31b27",
    },
  })
)(LinearProgress);

const ExpansionPanel = withStyles({
  root: {
    boxShadow: "none",
    boxSizing: "border-box",
    height: "100%",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
    "&>.MuiCollapse-container": {
      position: "absolute",
      // top: '100px',
      left: 0,
      display: "none",
    },
  },
  expanded: {
    position: "unset",
    "&>.MuiCollapse-container": {
      display: "block",
    },
  },
})(Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    boxSizing: "border-box",
    borderLeft: "1px solid rgba(0, 0, 0, .125)",
    borderBottom: "1px solid transparent",
    marginBottom: -1,
    backgroundColor: "#fff",
    height: "100%",
    transition: "background-color .3s ease",
    "&$expanded": {
      zIndex: 1600,
      borderBottom: "1px solid #fff",
      minHeight: "100%",
    },
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
    },
    "@media screen and (max-width: 1024px)": {
      width: 80,
    },
  },
  content: {
    justifyContent: "flex-end",
    "&$expanded": {},
    "@media screen and (max-width: 1280px)": {
      justifyContent: "center",
    },
  },
  expandIcon: {
    padding: "0 8px",
    "@media screen and (min-width: 1440px) and (max-width: 1600px)": {
      padding: "0 7px",
    },
    "@media screen and (min-width: 1280px) and (max-width: 1440px)": {
      padding: "0 5px",
    },
    "@media screen and (min-width: 1024px) and (max-width: 1200px)": {
      padding: "0 8px",
    },
  },
  expanded: {},
})(AccordionSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    position: "absolute",
    zIndex: 10,
    width: "calc(100vw - 5px)",
    padding: "0",
    backgroundColor: "#fff",
    flexDirection: "column",
  },
})(AccordionDetails);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: "50vw",
    },
    drawerPaper: {
      width: "50vw",
      top: "var(--headerHeight)",
      border: "none",
      "@media screen and (min-width: 1281px)": {
        display: "none",
      },
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 3px rgba(0, 0, 0, 0.3)",
        borderRadius: "3px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#f00",
        borderRadius: "3px",
        outline: "1px solid #ced4d9",
        height: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        height: "calc(100% - 20px)",
      },
      [theme.breakpoints.down("xs")]: {
        height: "100%",
      },
    },
  })
);

export const Header: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fullscreen = useSelector(fullscreenSelector);
  const panels = useSelector(panelsSelector);
  const seatings = useSelector(seatingsSelector);
  const search = useSelector(searchSelector);
  const searching = useSelector(searchingSelector);
  const matches = useSelector(matchesSelector);
  const isOpen = useSelector(drawerStateSelector);
  const tools = useSelector(toolsSelector);
  const contracts = useSelector(contractsSelector);
  const partnerList = useSelector(partnerListSelector);
  const [subscribeLink, setSubscibeLink] = React.useState<string>("");
  const [pdfLink, setPdfLink] = React.useState<string>("");

  React.useEffect(() => {
    const _instance = Contentful.getInstance();
    _instance.client.getEntry("359LIn2PBkxto2LdhtQsbD").then((res: any) => {
      setSubscibeLink(res.fields.link);
    });
    _instance.client.getAsset("44HLxJPBWI71N7m7fyd4T7").then((res: any) => {
      setPdfLink("https:" + res.fields.file.url);
    });
  }, []);

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [selectTools, setSelectTools] =
    React.useState<ToolsCategories>(undefined);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const configurator = window.location.pathname.includes("configurator");

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, _expanded: boolean) => {
      if (panel === "Search") {
        dispatch(changeControls({ name: "search", value: undefined }));
        dispatch(changeControls({ name: "matches", value: [] }));
        dispatch(changeControls({ name: "drawer_state", value: false }));
        setSelectTools(undefined);
      }
      setExpanded(_expanded ? panel : false);
      setSelectTools(undefined);
    };

  const makeRequest = useDebounce(() => {
    if (searchQuery && searchQuery.length > 1) {
      dispatch(changeControls({ name: "search", value: searchQuery }));
      !searching && dispatch(searchRequest());
    } else {
      dispatch(changeControls({ name: "search", value: undefined }));
      dispatch(changeControls({ name: "matches", value: [] }));
    }
  }, 700);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value.trim());
    makeRequest();
  };

  const handleTools = (categories: ToolsCategories) => {
    if (!selectTools) {
      setSelectTools(categories);
    } else if (categories.name !== selectTools.name) {
      setSelectTools(undefined);
      setTimeout(() => {
        setSelectTools(categories);
      }, 350);
    } else {
      setSelectTools(undefined);
    }
  };

  const downloadFabrixPdf = async (id: string) => {
    const _instance = Contentful.getInstance();
    const response: any = await _instance.client.getAsset('3nbJAFSrY3j8Q72HVieIoV');

    const fileUrl = "https:" + response?.fields?.file?.url;

    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderDetails = (type: string) => {
    switch (type) {
      case "Seating": {
        return (
          <ExpansionPanelDetails>
            <section
              className={`${styles.details_container} ${styles.seating}`}
            >
              {seatings && seatings.length
                ? seatings.map((el: Seating) => {
                    return (
                      <Link
                        className={styles.card_wrapper}
                        key={el.id}
                        to={`/${el.title.toLowerCase()}`}
                        onClick={() => {
                          dispatch(
                            changeControls({ name: "filter", value: "All" })
                          );
                          dispatch(
                            changeControls({ name: "sort", value: "All" })
                          );
                          setExpanded(false);
                        }}
                        style={
                          el.id === "2N6xOZdCvTvcUe0nUsjS6P"
                            ? {
                                order: -1,
                                textDecoration: "none",
                              }
                            : { textDecoration: "none" }
                        }
                      >
                        <Paper square elevation={0} className={styles.card}>
                          <div className={styles.card_media}>
                            <div
                              className={
                                isSafari && configurator
                                  ? styles.front_side_safari
                                  : styles.front_side
                              }
                            >
                              <img
                                className={styles.thumbnail}
                                src={`https:${el!.angle_thumbnail}${
                                  imageCompressConstants.productCardThumb
                                }`}
                              />
                            </div>
                            <div
                              className={
                                isSafari && configurator
                                  ? styles.back_side_safari
                                  : styles.back_side
                              }
                            >
                              <img
                                className={styles.thumbnail}
                                src={`https:${el!.thumbnail}${
                                  imageCompressConstants.productCardThumb
                                }`}
                              />
                            </div>
                          </div>
                          <Typography
                            variant="caption"
                            className={styles.title}
                          >
                            {el.title}
                          </Typography>
                        </Paper>
                      </Link>
                    );
                  })
                : null}
              {partnerList && partnerList.length
                ? partnerList
                    .filter((el: Partner) =>
                      el.title.toLowerCase().includes("tempur")
                    )
                    .map((el) => (
                      <Link
                        className={styles.card_wrapper}
                        key={el.partnerName}
                        to={`${Routes.PartnerPage}/${el.partnerName
                          .split(" ")
                          .join("_")
                          .toLowerCase()}`}
                        onClick={() => {
                          dispatch(
                            changeControls({ name: "filter", value: "All" })
                          );
                          dispatch(
                            changeControls({ name: "sort", value: "All" })
                          );
                          setExpanded(false);
                        }}
                        style={
                          el.id === "2N6xOZdCvTvcUe0nUsjS6P"
                            ? {
                                order: -1,
                                textDecoration: "none",
                              }
                            : { textDecoration: "none" }
                        }
                      >
                        <Paper square elevation={0} className={styles.card}>
                          <div className={styles.card_media}>
                            <div
                              className={
                                isSafari && configurator
                                  ? styles.front_side_safari
                                  : styles.front_side
                              }
                            >
                              <img
                                className={styles.thumbnail}
                                src={`https:${el!.logotype}${
                                  imageCompressConstants.productCardThumb
                                }`}
                              />
                            </div>
                            <div
                              className={
                                isSafari && configurator
                                  ? styles.back_side_safari
                                  : styles.back_side
                              }
                            >
                              <img
                                className={styles.thumbnail}
                                src={`https:${el!.logotype}${
                                  imageCompressConstants.productCardThumb
                                }`}
                              />
                            </div>
                          </div>
                          <Typography
                            variant="caption"
                            className={`${styles.title} ${styles.temur_title}`}
                          >
                            {el.title}
                          </Typography>
                        </Paper>
                      </Link>
                    ))
                : null}
            </section>
          </ExpansionPanelDetails>
        );
      }
      case "Material": {
        const content = panels.find((panel) => panel.type === type)!.content;
        return (
          <ExpansionPanelDetails>
            <section
              className={`${styles.details_container} ${styles.panel_details} ${styles.materials}`}
            >
              <div className={styles.preview}>
                <img
                  className={styles.thumbnail}
                  src={`/assets/images/header/materials/materials.jpg`}
                />
              </div>
              <article className={styles.article}>
                <Link
                  to={Routes.Material}
                  className={styles.title}
                  onClick={() => setExpanded(false)}
                >
                  Materials
                </Link>
                <Typography variant="caption" className={styles.description}>
                  Sleek leather. Lightweight, comfy mesh. Classic upholstery.
                  Only the highest-quality materials are used to cover Eurotech
                  chairs. Combine different materials for a unique look and feel
                  in a range of modern colors.
                </Typography>
              </article>
              <div className={`${styles.links} ${styles.material_links}`}>
                {content && content.length
                  ? content.map((el) => {
                      if (el.title === "Coated Perf Interactive promo" && pdfLink) {
                        return (
                          <a
                            onClick={() => setExpanded(false)}
                            className={styles.link}
                            key={el.id}
                            href={pdfLink}
                            // scroll={(el) =>
                            //   el.scrollIntoView({ behavior: "smooth" })
                            // }
                          >
                            {el.title}
                          </a>
                        );
                      }
                      return (
                        <>
                          {el.isDownload ? (
                            <button
                              className={styles.link}
                              onClick={() => downloadFabrixPdf(el.link)}
                            >
                              {el.title}
                            </button>
                          ) : (
                            <Link
                              onClick={() => setExpanded(false)}
                              className={styles.link}
                              key={el.id}
                              to={el.link}
                              scroll={(el) =>
                                el.scrollIntoView({ behavior: "smooth" })
                              }
                            >
                              {el.title}
                            </Link>
                          )}
                        </>
                      );
                    })
                  : null}
              </div>
            </section>
          </ExpansionPanelDetails>
        );
      }
      case "About": {
        const content = panels.find((panel) => panel.type === type)!.content;
        return (
          <ExpansionPanelDetails>
            <section
              className={`${styles.details_container} ${styles.panel_details} ${styles.about}`}
            >
              <div className={styles.info}>
                <div className={styles.preview}>
                  <img
                    className={styles.thumbnail}
                    src={`/assets/images/header/about/about.jpg`}
                  />
                </div>
                <article className={styles.article}>
                  <Link
                    to={`${Routes.About}`}
                    className={styles.title}
                    onClick={() => setExpanded(false)}
                  >
                    About
                  </Link>
                  <Typography variant="caption" className={styles.description}>
                    Eurotech has been the premier representative for quality and
                    ergonomic design in office chairs since 1979. Our commitment
                    to design, function, and form has resulted in over 1 billion
                    dollars in cumulative sales. See the Eurotech difference for
                    yourself.
                  </Typography>
                </article>
              </div>

              <div className={styles.links}>
                {content && content.length
                  ? content
                      .filter((item) => !item.link.includes("partner"))
                      .map((el) => {
                        return (
                          <Link
                            onClick={() => setExpanded(false)}
                            className={`${styles.link} ${styles.about_link}`}
                            key={el.id}
                            to={el.link}
                            scroll={(el) =>
                              el.scrollIntoView({ behavior: "smooth" })
                            }
                          >
                            {el.title}
                          </Link>
                        );
                      })
                  : null}
              </div>
              <section className={styles.partners_container}>
                <div className={styles.mainTitle}>Our partners</div>
                {partnerList &&
                  partnerList
                    .filter(
                      (item: Partner) =>
                        !item.title.toLowerCase().includes("tempur")
                    )
                    .map((partner: Partner) => {
                      return (
                        <Link
                          to={`${Routes.PartnerPage}/${partner.partnerName
                            .split(" ")
                            .join("_")
                            .toLowerCase()}`}
                          key={partner.id}
                          className={styles.partner}
                          onClick={() => setExpanded(false)}
                        >
                          <div className={styles.partner_logo}>
                            <img src={partner.logotype} alt="" />
                          </div>
                          <Typography className={styles.before_title}>
                            Explore
                          </Typography>
                          <Typography className={styles.title}>
                            {partner.title}
                          </Typography>
                        </Link>
                      );
                    })}
              </section>
            </section>
          </ExpansionPanelDetails>
        );
      }
      case "Contact": {
        const content = panels.find((panel) => panel.type === type)!.content;
        return (
          <ExpansionPanelDetails>
            <section
              className={`${styles.details_container} ${styles.panel_details}`}
            >
              <div className={styles.preview}>
                <img
                  className={styles.thumbnail}
                  src={`/assets/images/header/contact/contact.jpg`}
                  alt=""
                />
              </div>
              <article className={styles.article}>
                <Link
                  to={Routes.Contact}
                  className={styles.title}
                  onClick={() => setExpanded(false)}
                >
                  Contact
                </Link>
                <Typography variant="caption" className={styles.description}>
                  525 Hempstead Turnpike
                  <br />
                  West Hempstead, NY 11552
                  <br />
                  T: 1.800.637.0005
                  <br />
                  F: 1.516.481.8141
                </Typography>
              </article>
              <div className={styles.links}>
                {content && content.length
                  ? content.map((el) => {
                      if (el.title === "Dealer Login") {
                        return (
                          <a className={styles.link} key={el.id} href={el.link}>
                            {el.title}
                          </a>
                        );
                      }
                      if (el.title === "Subscibe To Our Newsletters") {
                        return (
                          <a
                            className={styles.link}
                            key={el.id}
                            onClick={() =>
                              window.open(subscribeLink, "_blank").focus()
                            }
                          >
                            {el.title}
                          </a>
                        );
                      }
                      return (
                        <Link
                          onClick={() => setExpanded(false)}
                          className={styles.link}
                          key={el.id}
                          to={el.link}
                        >
                          {el.title}
                        </Link>
                      );
                    })
                  : null}
              </div>
            </section>
          </ExpansionPanelDetails>
        );
      }
      case "Contracts": {
        const content = panels.find((panel) => panel.type === type)!.content;
        return (
          <ExpansionPanelDetails>
            <section
              className={`${styles.details_container} ${styles.contract}`}
            >
              <div className={styles.preview}>
                <img
                  className={styles.thumbnail}
                  src={`/assets/images/header/contract/GSA.png`}
                />
              </div>
              <article className={styles.article}>
                {/*<Link to={'/'} className={styles.title}*/}
                {/*      onClick={() => setExpanded(false)}>GSA Advantage</Link>*/}
                <Typography
                  variant="caption"
                  className={styles.description}
                ></Typography>
              </article>
              <div className={styles.tools_categories}>
                {content && content.length
                  ? content.map((el) => {
                      return (
                        <Link
                          onClick={() => setExpanded(false)}
                          className={styles.categories}
                          key={el.id}
                          to={el.link}
                        >
                          {el.title}
                        </Link>
                      );
                    })
                  : null}
                {contracts && contracts.categories
                  ? contracts.categories.map((el) => {
                      return (
                        <div
                          className={
                            selectTools && el.name === selectTools.name
                              ? styles.categories_selected
                              : styles.categories
                          }
                          key={el.name}
                          onClick={() => handleTools(el)}
                        >
                          {el.name}
                          <ChevronRightIcon className={styles.icon} />
                        </div>
                      );
                    })
                  : null}
              </div>

              <div className={styles.links_wrapper}>
                <div
                  className={
                    selectTools
                      ? styles.links_container
                      : styles.links_container_unmount
                  }
                >
                  {selectTools && selectTools.content
                    ? selectTools.content.map((el) => {
                        return (
                          <a
                            className={styles.link_pdf}
                            target="_blank"
                            key={el.title}
                            href={`https:${el.entries}`}
                          >
                            <div className={styles.content_container}>
                              <div className={styles.preview_link}>
                                <img
                                  src="/assets/images/header/tools/document.png"
                                  alt="document"
                                />
                              </div>
                              <Typography className={styles.link_title}>
                                {el.title}
                              </Typography>
                            </div>
                          </a>
                        );
                      })
                    : null}
                </div>
              </div>
            </section>
          </ExpansionPanelDetails>
        );
      }
      case "Resources": {
        return (
          <ExpansionPanelDetails>
            <section className={`${styles.details_container} ${styles.tools}`}>
              <div className={styles.preview}>
                <img
                  className={styles.thumbnail}
                  src={`/assets/images/header/tools/preview.jpg`}
                />
              </div>

              <div className={styles.tools_categories}>
                <Link
                  to="/resources"
                  className={styles.categories}
                  onClick={() => handleClick()}
                >
                  Design Resources
                </Link>

                {tools && tools.categories
                  ? tools.categories.map((el) => {
                      return (
                        <div
                          className={
                            selectTools && el.name === selectTools.name
                              ? styles.categories_selected
                              : styles.categories
                          }
                          key={el.name}
                          onClick={() => handleTools(el)}
                        >
                          {el.name}
                          <ChevronRightIcon className={styles.icon} />
                        </div>
                      );
                    })
                  : null}
                {/* <Link
                  to="/parts"
                  className={styles.categories}
                  onClick={() => handleClick()}
                >
                  Purchase missed parts
                </Link> */}
              </div>
              <div className={styles.links_wrapper}>
                <div
                  className={
                    selectTools
                      ? styles.links_container
                      : styles.links_container_unmount
                  }
                >
                  {selectTools &&
                  selectTools.contentType === "file" &&
                  selectTools.content
                    ? selectTools.content.map((el) => {
                        return (
                          <a
                            className={styles.link_pdf}
                            target="_blank"
                            key={el.title}
                            href={`https:${el.entries}`}
                          >
                            <div className={styles.content_container}>
                              <div className={styles.preview_link}>
                                <img
                                  src="/assets/images/header/tools/document.png"
                                  alt="document"
                                />
                              </div>
                              <Typography className={styles.link_title}>
                                {el.title}
                              </Typography>
                            </div>
                          </a>
                        );
                      })
                    : null}

                  {selectTools &&
                  selectTools.contentType === "video" &&
                  selectTools.content
                    ? selectTools.content.map((el) => {
                        return (
                          <div
                            key={el.title}
                            className={styles.link_pdf}
                            onClick={() => handleChangeVideo(el)}
                          >
                            <div className={styles.content_container}>
                              <div className={styles.preview_link}>
                                <PlayCircleFilledWhiteOutlinedIcon
                                  style={{ color: "grey" }}
                                />
                              </div>
                              <Typography className={styles.link_title}>
                                {el.title}
                              </Typography>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </section>
          </ExpansionPanelDetails>
        );
      }
      case "Sign In": {
        return <ExpansionPanelDetails>Sign In</ExpansionPanelDetails>;
      }
      case "Search": {
        return (
          <ExpansionPanelDetails>
            <section className={`${styles.details_container} ${styles.search}`}>
              <InputBase
                classes={{ root: styles.search_field }}
                placeholder="Type to Search..."
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearch}
                autoFocus={true}
                // value={search}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
              {searching && <SearchProgress />}
            </section>
            <React.Fragment>
              {!searching && matches && matches.length ? (
                <List
                  className={`${styles.details_container} ${styles.matches}`}
                >
                  {matches.map((model: Model) => {
                    return (
                      model.category && (
                        <Link
                          key={model.title}
                          to={`/${model.category.toLowerCase()}/${model.title
                            .split(" ")
                            .join("_")
                            .toLowerCase()}`}
                          onClick={() => handleClick()}
                          style={{ textDecoration: "none", color: "unset" }}
                        >
                          <ListItem button style={{ paddingLeft: 0 }}>
                            <Avatar
                              variant="square"
                              alt=""
                              src={`https:${model.thumbnail}${imageCompressConstants.searchResultThumb}`}
                              className={styles.avatar}
                            />
                            <ListItemText
                              style={{ flex: "0 1 auto" }}
                              primary={model.title.toUpperCase()}
                            />
                            <ListItemText
                              style={{
                                width: "min-content",
                                color: "#858585",
                                textAlign: "left",
                                marginLeft: 10,
                              }}
                              primary={`${model.sku.toUpperCase()}`}
                            />
                          </ListItem>
                        </Link>
                      )
                    );
                  })}
                </List>
              ) : null}
              {!searching && matches && !matches.length && search ? (
                <List
                  className={`${styles.details_container} ${styles.matches}`}
                >
                  <ListItem style={{ paddingLeft: 10 }}>
                    <ListItemText primary={"Not Found".toUpperCase()} />
                  </ListItem>
                </List>
              ) : null}
            </React.Fragment>
          </ExpansionPanelDetails>
        );
      }
      default:
        return null;
    }
  };

  const headerTransition = {
    unmounted: { transform: "translateY(0)", zIndex: 1400 },
    entering: { transform: "translateY(0)", zIndex: 1400 },
    entered: { transform: "translateY(0)", marginBottom: 0, zIndex: 1400 },
    exiting: {
      transform: "translateY(-100%)",
      marginBottom: "calc(var(--headerHeight) * -1)",
      zIndex: 1400,
    },
    exited: {
      transform: "translateY(-100%)",
      marginBottom: "calc(var(--headerHeight) * -1)",
      zIndex: 1400,
    },
  };

  React.useEffect(() => {
    dispatch(fetchTools());
    dispatch(fetchContracts());
  }, [dispatch]);

  React.useEffect(() => {
    const el = document.querySelector("body");
    if (!isOpen) {
      el.style.overflow = "auto";
      drawerController({
        left: false,
        right: false,
        panel: undefined,
      });
    } else {
      setExpanded(false);
      el.style.overflow = "hidden";
    }
  }, [isOpen]);

  const handleClose = React.useCallback(() => {
    setExpanded(false);
    setSelectTools(undefined);
    dispatch(changeControls({ name: "drawer_state", value: false }));
  }, [dispatch]);

  React.useEffect(() => {
    const el = document.getElementById("event_listener");
    el.addEventListener("click", handleClose);
    return () => {
      el.removeEventListener("click", handleClose);
    };
  }, [handleClose]);

  type Anchor = "left" | "right";

  const toggleDrawer = (anchor: Anchor, open: boolean, _panel?: string) => {
    drawerController({ ...drawer, [anchor]: open, panel: _panel });
  };

  const [drawer, drawerController] = React.useState({
    left: false,
    right: false,
    panel: undefined,
  });

  const handleClick = () => {
    setExpanded(false);
    setSelectTools(undefined);
    dispatch(changeControls({ name: "filter", value: "All" }));
    dispatch(changeControls({ name: "sort", value: "All" }));
    dispatch(changeControls({ name: "drawer_state", value: false }));
    dispatch(changeControls({ name: "search", value: undefined }));
    dispatch(changeControls({ name: "matches", value: [] }));
  };

  const handleChangeVideo = (props: any) => {
    dispatch(changeControls({ name: "selectVideo", value: props }));
    dispatch(changeControls({ name: "showVideo", value: true }));
  };

  return (
    <React.Fragment>
      <Transition timeout={350} in={!fullscreen}>
        {(state) => (
          <header
            className={styles.header}
            style={{ ...headerTransition[state] }}
          >
            <section className={styles.header_mobile}>
              <MenuControl
                aria-label="dropdown_menu"
                onClick={() => {
                  dispatch(
                    changeControls({
                      name: "drawer_state",
                      value: !isOpen,
                    })
                  );
                  setSelectTools(undefined);
                }}
              >
                {isOpen ? (
                  <CloseIcon fontSize="inherit" />
                ) : (
                  <MenuIcon fontSize="inherit" />
                )}
              </MenuControl>
            </section>
            <section className={styles.header_section}>
              <a
                href="https://rgcompanies.com/"
                target="_blank"
                className={styles.logotype}
              >
                <img src={"/assets/images/raynorGroup.png"} alt="" />
              </a>
              {panels.slice(0, 3).map((panel: any) => {
                return (
                  <ExpansionPanel
                    key={panel.type}
                    square
                    expanded={expanded === panel.type}
                    onChange={handleChange(panel.type)}
                    TransitionProps={{ unmountOnExit: true }}
                  >
                    <ExpansionPanelSummary
                      className={`${styles.expansion_panel_summary} ${
                        styles[panel.type.toLowerCase()]
                      }`}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography variant="caption" className={styles.title}>
                        {panel.type}
                      </Typography>
                    </ExpansionPanelSummary>
                    {expanded && renderDetails(expanded)}
                  </ExpansionPanel>
                );
              })}
            </section>

            <Link
              to={Routes.Homepage}
              className={styles.logotype}
              onClick={() => handleClick()}
            >
              <img src={"/assets/images/EUROTECH_Logo_red.png"} alt="" />
            </Link>

            <section className={styles.header_section}>
              {panels.slice(3).map((panel: any) => {
                return (
                  <ExpansionPanel
                    key={panel.type}
                    square
                    expanded={expanded === panel.type}
                    onChange={handleChange(panel.type)}
                    TransitionProps={{ unmountOnExit: panel.type !== "Search" }}
                  >
                    <ExpansionPanelSummary
                      className={`${styles.expansion_panel_summary} ${
                        styles[panel.type.split(" ").join("_").toLowerCase()]
                      }`}
                      expandIcon={
                        panel.type !== "Sign In" && panel.type !== "Search" ? (
                          <ExpandMoreIcon />
                        ) : null
                      }
                    >
                      {panel.type !== "Search" ? (
                        <Typography variant="caption" className={styles.title}>
                          {panel.type}
                        </Typography>
                      ) : (
                        <div className={styles.search_btn}>
                          <SearchIcon />
                          <Typography
                            variant="caption"
                            className={styles.title}
                          >
                            {panel.type}
                          </Typography>
                        </div>
                      )}
                    </ExpansionPanelSummary>
                    {expanded && renderDetails(expanded)}
                  </ExpansionPanel>
                );
              })}
              <Link
                className={styles.link}
                to={Routes.Configurator}
                onClick={() => handleClick()}
              >
                Configure
              </Link>
            </section>
            <section className={styles.header_mobile}>
              <ExpansionPanel
                square
                expanded={expanded === "Search"}
                onChange={handleChange("Search")}
                TransitionProps={{ unmountOnExit: false }}
              >
                <ExpansionPanelSummary
                  className={`${styles.expansion_panel_summary} ${styles.search}`}
                >
                  <SearchIcon />
                </ExpansionPanelSummary>
                {expanded && renderDetails(expanded)}
              </ExpansionPanel>
              <Link
                className={styles.link}
                to={Routes.Configurator}
                onClick={() => handleClick()}
              >
                Configure
              </Link>
            </section>
          </header>
        )}
      </Transition>

      <Drawer
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
        anchor="left"
        open={isOpen}
        variant="persistent"
      >
        <Box className={styles.drawer_content}>
          <List component="nav" className={styles.list}>
            <a
              href="https://rgcompanies.com/"
              target="_blank"
              className={styles.list_item}
              onClick={() => handleClick()}
            >
              <Typography variant="caption" className={styles.label}>
                Raynor Group
              </Typography>
              <ChevronRightIcon className={styles.icon} />
            </a>
            {panels
              .filter((panel) => panel.type !== "Search")
              .map((panel) => {
                return (
                  <ListItem
                    className={`${styles.list_item} ${
                      panel.content && panel.type === drawer.panel
                        ? styles.list_item_active
                        : ""
                    }`}
                    key={panel.type}
                    onClick={() =>
                      panel.content
                        ? toggleDrawer("right", true, panel.type)
                        : handleClick()
                    }
                  >
                    {panel.type !== '"Solutions"' &&
                    panel.type !== "About" &&
                    panel.type !== "Material" ? (
                      <Typography variant="caption" className={styles.label}>
                        {panel.type}
                      </Typography>
                    ) : (
                      <Link
                        to={`/${panel.type.toLowerCase()}`}
                        className={styles.label}
                      >
                        {panel.type}
                      </Link>
                    )}

                    {panel.type && (
                      <ChevronRightIcon className={styles.link_icon} />
                    )}
                  </ListItem>
                );
              })}
          </List>
          <Link
            className={`${styles.list_item} ${styles.configure_link}`}
            to={Routes.Configurator}
            onClick={() => handleClick()}
          >
            <Typography variant="caption" className={styles.label}>
              Configure
            </Typography>
            <ChevronRightIcon className={styles.icon} />
          </Link>
        </Box>
      </Drawer>
      <Drawer
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
        anchor="right"
        open={drawer.right}
        variant="persistent"
      >
        <Box
          className={`${styles.drawer_content} ${styles.drawer_sub_content}`}
        >
          {drawer.panel &&
            drawer.panel !== "Resources" &&
            drawer.panel !== "Contracts" && (
              <List component="nav" className={styles.list}>
                {drawer.panel !== "Seating" &&
                drawer.panel !== "Resources" &&
                drawer.panel !== "Contracts" ? (
                  panels
                    .find((panel) => panel.type === drawer.panel)
                    ?.content.map((el) => {
                      if (el.title === "Dealer Login") {
                        return (
                          <a
                            className={styles.list_item}
                            key={el.id}
                            href={el.link}
                          >
                            <Typography
                              variant="caption"
                              className={styles.label}
                            >
                              {el.title}
                            </Typography>
                          </a>
                        );
                      }

                      if (el.isDownload) {
                        return (
                          <button
                            className={styles.list_item}
                            onClick={() => downloadFabrixPdf(el.link)}
                          >
                            <Typography
                              variant="caption"
                              className={styles.label}
                            >
                              {el.title}
                            </Typography>
                          </button>
                        );
                      }

                      return (
                        <Link
                          className={styles.list_item}
                          key={el.id}
                          to={el.link.toLowerCase()}
                          onClick={() => handleClick()}
                        >
                          <Typography
                            variant="caption"
                            className={styles.label}
                          >
                            {el.title}
                          </Typography>
                        </Link>
                      );
                    })
                ) : (
                  <>
                    {seatings &&
                      seatings.map((seating: Seating) => {
                        return (
                          <Link
                            className={styles.list_item}
                            key={seating.id}
                            to={`/${seating.title.toLowerCase()}`}
                            onClick={() => handleClick()}
                          >
                            <Typography
                              variant="caption"
                              className={styles.label}
                            >
                              {seating.title}
                            </Typography>
                          </Link>
                        );
                      })}
                    {partnerList && partnerList
                      .filter((el) =>
                        el.title.toLowerCase().includes("discontinued")
                      )
                      .map((el) => (
                        <Link
                          key={el.partnerName}
                          to={`${Routes.PartnerPage}/${el.partnerName
                            .split(" ")
                            .join("_")
                            .toLowerCase()}`}
                          className={styles.list_item}
                          onClick={() => handleClick()}
                        >
                          <Typography
                            variant="caption"
                            className={styles.label}
                          >
                            {el.title}
                          </Typography>
                        </Link>
                      ))}
                  </>
                )}
              </List>
            )}
          {drawer.panel === "Resources" && (
            <section className={`${styles.tools}`}>
              <div className={styles.preview}>
                <img
                  className={styles.thumbnail}
                  src={`/assets/images/header/contract/GSA.png`}
                />
              </div>

              <div className={styles.tools_categories}>
                <Link
                  to="/resources"
                  className={styles.categories}
                  onClick={() => handleClick()}
                >
                  Design Resources
                </Link>
                {/* <Link
                  to="/parts"
                  className={styles.categories}
                  onClick={() => handleClick()}
                >
                  Purchase missed parts
                </Link> */}

                {tools && tools.categories
                  ? tools.categories.map((el) => {
                      return (
                        <div
                          className={
                            selectTools && el.name === selectTools.name
                              ? styles.categories_selected
                              : styles.categories
                          }
                          key={el.name}
                          onClick={() => handleTools(el)}
                        >
                          <Typography className={styles.label}>
                            {el.name}
                          </Typography>
                          <ChevronRightIcon className={styles.icon} />
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className={styles.links_wrapper}>
                <div
                  className={
                    selectTools
                      ? styles.links_container
                      : styles.links_container_unmount
                  }
                >
                  {selectTools &&
                  selectTools.contentType === "file" &&
                  selectTools.content
                    ? selectTools.content.map((el) => {
                        return (
                          <a
                            className={styles.link_pdf}
                            target="_blank"
                            key={el.title}
                            href={`https:${el.entries}`}
                          >
                            <div className={styles.content_container}>
                              <div className={styles.preview_link}>
                                <img
                                  src="/assets/images/header/tools/document.png"
                                  alt="document"
                                />
                              </div>
                              <Typography className={styles.link_title}>
                                {el.title}
                              </Typography>
                            </div>
                          </a>
                        );
                      })
                    : null}

                  {selectTools &&
                  selectTools.contentType === "video" &&
                  selectTools.content
                    ? selectTools.content.map((el) => {
                        return (
                          <div
                            key={el.title}
                            className={styles.link_pdf}
                            onClick={() => handleChangeVideo(el)}
                          >
                            <div className={styles.content_container}>
                              <div className={styles.preview_link}>
                                <PlayCircleFilledWhiteOutlinedIcon
                                  style={{ color: "grey" }}
                                />
                              </div>
                              <Typography className={styles.link_title}>
                                {el.title}
                              </Typography>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </section>
          )}
          {drawer.panel === "Contracts" && (
            <section className={`${styles.tools}`}>
              <div className={styles.tools_categories}>
                {panels
                  .find((panel) => panel.type === drawer.panel)
                  ?.content.map((el) => {
                    return (
                      <Link
                        className={styles.categories}
                        key={el.id}
                        to={el.link.toLowerCase()}
                        onClick={() => handleClick()}
                      >
                        {el.title}
                      </Link>
                    );
                  })}
                {contracts && contracts.categories
                  ? contracts.categories.map((el) => {
                      return (
                        <div
                          className={
                            selectTools && el.name === selectTools.name
                              ? styles.categories_selected
                              : styles.categories
                          }
                          key={el.name}
                          onClick={() => handleTools(el)}
                        >
                          {el.name}
                          <ChevronRightIcon className={styles.icon} />
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className={styles.links_wrapper}>
                <div
                  className={
                    selectTools
                      ? styles.links_container
                      : styles.links_container_unmount
                  }
                >
                  {selectTools && selectTools.content
                    ? selectTools.content.map((el) => {
                        return (
                          <a
                            className={styles.link_pdf}
                            target="_blank"
                            key={el.title}
                            href={`https:${el.entries}`}
                          >
                            <div className={styles.content_container}>
                              <div className={styles.preview_link}>
                                <img
                                  src="/assets/images/header/tools/document.png"
                                  alt="document"
                                />
                              </div>
                              <Typography className={styles.link_title}>
                                {el.title}
                              </Typography>
                            </div>
                          </a>
                        );
                      })
                    : null}
                </div>
              </div>
            </section>
          )}
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default Header;
