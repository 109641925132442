import Routes from "../../../app.router/router.constants";

// import fabrixExpress from ''

export const panels = [
  {
    type: "Seating",
    content: [
      {
        id: 0,
        title: "Executive",
        thumbnail: "seating_chair",
        link: Routes.ExecutivePage,
      },
      {
        id: 1,
        title: "Task",
        thumbnail: "seating_chair",
        link: Routes.TaskPage,
      },
      {
        id: 2,
        title: "Conference",
        thumbnail: "seating_chair",
        link: Routes.ConferencePage,
      },
      {
        id: 3,
        title: "Guest",
        thumbnail: "seating_chair",
        link: Routes.GuestPage,
      },
      {
        id: 4,
        title: "Stack",
        thumbnail: "seating_chair",
        link: Routes.StackPage,
      },
      {
        id: 5,
        title: "Stools",
        thumbnail: "seating_chair",
        link: Routes.StoolsPage,
      },
      {
        id: 6,
        title: "School",
        thumbnail: "seating_chair",
        link: Routes.SchoolPage,
      },
      {
        id: 7,
        title: "Accessories",
        thumbnail: "seating_chair",
        link: Routes.Accessories,
      },
    ],
  },
  {
    type: "Material",
    preview: "img_placeholder.png",
    content: [
      {
        id: 0,
        title: "Leather",
        link: "/material#leather",
      },
      {
        id: 1,
        title: "Outlast Technology",
        link: "/material#outlast",
      },
      {
        id: 2,
        title: "Mesh",
        link: "/material#mesh",
      },
      {
        id: 3,
        title: "Healthcare",
        link: "/material#healthcare",
      },
      {
        id: 4,
        title: "Fabrix Swatch Catalog",
        link: "/fabrix-catalog",
      },
      {
        id: 5,
        title: "Coated Perf Interactive promo",
        link: "/material#custom",
      },
      {
        id: 6,
        title: "Fabrix Express Offers",
        // link: "/assets/downloads/Fabrix_Express.pdf",
        link: '4dFc2xaGu6VIbH3bpgjDXX',
        isDownload: true,
      }
    ],
  },
  {
    type: "About",
    content: [
      {
        id: 0,
        title: "About",
        link: "/about",
      },
      {
        id: 1,
        title: "Certifications & Memberships",
        link: "/about/#certifications",
      },
      {
        id: 2,
        title: "TEMPUR®",
        link: "/partner/tempur®",
      },
      {
        id: 3,
        title: "Sharper Image",
        link: "/partner/sharper_image",
      },
      {
        id: 4,
        title: "Elizabeth Sutton",
        link: "/partner/elizabeth_sutton",
      },
      {
        id: 5,
        title: "Raynor Gaming",
        link: "/partner/raynor_gaming",
      },
    ],
  },
  {
    type: "Resources",
    content: []
  },
  {
    type: "Contracts",
    content: [
      {
        id: 0,
        title: "GSA Advantage",
        link: "/gsa-advantage",
      },
      {
        id: 1,
        title: "GSA Program",
        link: "/gsa-program",
      },
    ],
  },
  {
    type: "Contact",
    content: [
      {
        id: 0,
        title: "Headquarters",
        link: "/contact",
      },
      {
        id: 1,
        title: "Find a Representative",
        link: "/contact/?q=representative",
      },
      {
        id: 2,
        title: "Dealer Login",
        link: "https://shop.eurotechseating.com/bus/login.aspx?Target=brand-exclusives-c15.aspx",
      },
      {
        id: 3,
        title: "Subscribe To Our Newsletters",
        link: ''
      },
    ],
  },
  {
    type: "Search",
  },
];

export const sliders = [
  {
    location: "homepage",
    title: "Our Most Popular Models",
    slides: [
      {
        id: 0,
        title: "Ergohuman Leather",
        label: "Executive / Task",
        category: "Task",
        thumbnail: "./assets/images/chairimg2.png",
        price: 1799,
      },
      {
        id: 1,
        title: "Ergohuman Leather Seat & Mesh Back",
        label: "Executive / Task",
        category: "Task",
        thumbnail: "./assets/images/chairimg3.png",
        price: 1720,
      },
      {
        id: 2,
        title: "Ergohuman Fabric",
        label: "Executive / Task",
        category: "Task",
        thumbnail: "./assets/images/chairimg1.png",
        price: 1720,
      },
      {
        id: 3,
        title: "Ergohuman Leather",
        label: "Executive / Task",
        category: "Task",
        thumbnail: "./assets/images/chairimg2.png",
        price: 1799,
      },
    ],
  },
  {
    location: "raynor",
    title: "",
    slides: [
      {
        id: 0,
        title: "Ergohuman Fabric",
        label: "Executive / Task",
        category: "Task",
        thumbnail: "./assets/images/chairimg1.png",
      },
      {
        id: 1,
        title: "Ergohuman Leather",
        label: "Executive / Task",
        category: "Task",
        thumbnail: "./assets/images/chairimg2.png",
      },
      {
        id: 2,
        title: "Ergohuman Leather Seat & Mesh Back",
        label: "Executive / Task",
        category: "Task",
        thumbnail: "./assets/images/chairimg3.png",
      },
      {
        id: 3,
        title: "Ergohuman Fabric",
        label: "Executive / Task",
        category: "Task",
        thumbnail: "./assets/images/chairimg1.png",
      },
      {
        id: 4,
        title: "Ergohuman Leather",
        label: "Executive / Task",
        category: "Task",
        thumbnail: "./assets/images/chairimg2.png",
      },
    ],
  },
];

export const categories = [
  {
    location: "executive",
    title: "Comfort, Meet Style",
    description:
      "Executive office chairs from Eurotech are the pinnacle of luxurious comfort and exceptional style. An ideal choice for boardroom seating or C-suite offices, Eurotech’s Executive Office Chair designs include various ergonomic features. You’ll have firm support while finishing up a long day in the office",
    thumbnail: "executive_bg.png",
  },
  {
    location: "task",
    title: "The Unsung Hero",
    description:
      "Big and tall, short and petite, and everything in-between — Task office chairs are engineered to fit any body type. Ergonomic options give you the ability to adjust the chair to fit your height, weight, and seating comfort. Task Office Chair designs from Eurotech are incredibly versatile, offering a wide range of options for ergonomic features and style. Browse our selection and contact our representatives today for expert guidance on choosing the perfect Task Office Chair.",
    thumbnail: "task_bg.png",
  },
  {
    location: "conference",
    title: "Professional. Affordable.",
    description:
      "Outfit your boardroom with stylish and durable Conference Office Chairs from Eurotech. These professional and affordable Conference Chairs keep the health of your back and comfort of your visitors in mind. Versatile design options for silhouettes, fabric, and ergonomic features are guaranteed to fit any boardroom decor and reflect the style of your brand.",
    thumbnail: "conference_bg.png",
  },
  {
    location: "guest",
    title: " Make a Great First Impression",
    description:
      "Furnish your reception area or waiting room with stylish and comfortable Eurotech Guest Chairs. Make waiting less frustrating or uncomfortable for your guests when you outfit your waiting room with one of these ergonomic, high-quality Guest chair designs. Choose from mesh, leather, fabric, and a range of sizes and silhouettes to reflect your style while keeping clients and guests relaxed.",
    thumbnail: "guest_bg.png",
  },
  {
    location: "stack",
    title: "Fast Set Up and Storage",
    description:
      "Stacking Chairs from Eurotech are durable, attractive, and convenient. Choose from a range of high-quality, contoured fabric chairs and robust plastic designs for quick set up and storage. Our Stack Chairs are ideal for banquets, conferences, events, classrooms, and team meetings. Free up storage room and make it quick and easy to set up for an event with our high-quality Stack Chairs.",
    thumbnail: "stack_bg.png",
  },
  {
    location: "stools",
    title: "Work Hard. Work Comfortably.",
    description:
      "Eurotech Office Stools are designed to swivel, pivot, and adjust to your height for maximum comfort. Our Stool designs are ideal for drafting tables, high counters, and bars. Work comfortably in one of our ergonomic Office Stool designs, and choose from a range of styles, colors, and coverings for an office Stool that fits your interior.",
    thumbnail: "education_bg.png",
  },
];

export const features = [
  {
    key: "Arm Depth Adjustment",
    icon: "arm depth adjustment.png",
  },
  {
    key: "Center-Tilt",
    icon: "center or swivel-tilt.png",
  },
  {
    key: "Swivel-Tilt",
    icon: "center or swivel-tilt.png",
  },
  {
    key: "Arm Height Adjustment",
    icon: "arm height adjustment.png",
  },
  {
    key: "Back Angle Adjustment",
    icon: "back angle adjustment.png",
  },
  {
    key: "Back Height Adjustment",
    icon: "back height adjustment.png",
  },
  {
    key: "Center or Swivel-Tilt",
    icon: "center or swivel-tilt.png",
  },
  {
    key: "Forward Seat Tilt",
    icon: "forward seat tilt.png",
  },
  {
    key: "Knee-Tilt",
    icon: "knee-tilt.png",
  },
  {
    key: "2.8'' Ratchet Back Height Adjustment",
    icon: "ratchet back height adjustment.png",
  },
  {
    key: "Seat Angle Adjustment",
    icon: "seat angle adjustment.png",
  },
  {
    key: "Seat Depth Adjustment",
    icon: "seat depth adjustment.png",
  },
  {
    key: "Seat Height Adjustment",
    icon: "seat height adjustment.png",
  },
  {
    key: "Swivel Arm",
    icon: "swivel arm.png",
  },
  {
    key: "Synchro-Tilt",
    icon: "synchro-tilt.png",
  },
  {
    key: "Tilt Lock",
    icon: "tilt lock.png",
  },
  {
    key: "Tilt Tension Control",
    icon: "tilt tension control.png",
  },
  {
    key: "Waterfall Seat",
    icon: "waterfall seat.png",
  },
  {
    key: "Width Adjustable Arms",
    icon: "width adjustable arms.png",
  },
];
