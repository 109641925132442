import Contentful from './contentful'
import Model from '../../types/Model'

const _instance = Contentful.getInstance();

export const getDiscountedChairs = async () => {
  const response = await _instance.client.getEntries({
    'fields.is_discounted': true,
    content_type: 'model'
  })

  return response.items.map((el: any) => new Model(el));
}
